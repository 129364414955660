import Head from 'next/head';
import { DOMAIN_URL, SITE_NAME } from '@artica/constants';

export default function DefaultHead({ children }) {
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <meta name="format-detection" content="telephone=no" />
      
      <meta name="referrer" content="no-referrer-when-downgrade" />
      <meta name="robots" content="all" />
      <meta content="en_US" property="og:locale" />
      <meta content={ SITE_NAME } property="og:site_name" />
      <meta content="website" property="og:type" />
      <meta content={ DOMAIN_URL } property="og:url" />
      <meta content="Home" property="og:title" />

      <meta content={ `${DOMAIN_URL}/logo.jpg` } property="og:image" />
      <meta content="256" property="og:image:width" />
      <meta content="256" property="og:image:height" />
      
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon.png" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-precomposed.png" />
      <link rel="icon" type="image/jpg" href="/logo.jpg" />
      <link rel="shortcut icon" href="/favicon.ico" />

      <link rel="amphtml" href={ `${DOMAIN_URL}/amp` } />
      <link href={ DOMAIN_URL } rel="home" />

      <link type="text/plain" href={ `${DOMAIN_URL}/humans.txt` } rel="author" />

      { children }
    </Head>
  );
};